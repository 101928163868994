import React, { useEffect, useMemo, useState, useRef, useCallback, useContext } from 'react';
import { BackgroundImage } from '../../../image';
import { useWindowDimensions } from '../../../../common/use-window-dimensions';
import gsap from 'gsap';
import { IconClose } from '../../../icons';
import { ArrowFlag } from './arrow-flag';
import { graphql } from 'gatsby';
import { AuthContext, hideForVisibility } from '../../../auth';
import './styles.scss';

export const SliceAdvisorsGrid = ({ primary, items }) => {
  const auth = useContext(AuthContext);
  const [ activeAdvisor, setActiveAdvisor ] = useState(null);
  const advisorRefs = useRef(new Array());
  const bodyRefs = useRef(new Array());
  const { width } = useWindowDimensions();
  const [ widthAtActive, setWidthAtActive ] = useState(width);

  useEffect(() => {
    if (activeAdvisor !== null && width !== widthAtActive) {
      const currAdvisor = advisorRefs.current[activeAdvisor];
      const currBody = bodyRefs.current[activeAdvisor];

      if (currAdvisor.style.paddingBottom !== currBody.scrollHeight + 48) {
        currAdvisor.style.paddingBottom = `${currBody.scrollHeight + 48}px`;
      }
    }
  }, [ width, activeAdvisor, widthAtActive ]);

  useEffect(() => {
    if (activeAdvisor !== null) {
      const currAdvisor = advisorRefs.current[activeAdvisor];
      const currBody = bodyRefs.current[activeAdvisor];

      const bodyHeight = currBody.scrollHeight + 48;

      gsap.to(currAdvisor, { duration: 0.25, paddingBottom: bodyHeight });
      setWidthAtActive(width);
    }
  }, [ activeAdvisor, width ]);

  const handleAdvisorClick = useCallback((index) => {
    const currAdvisor = advisorRefs.current[activeAdvisor];
    gsap.to(currAdvisor, { duration: 0.25, paddingBottom: 0 });

    if (activeAdvisor === index) {
      setActiveAdvisor(null);
    } else {
      setActiveAdvisor(index);
    }
  }, [ activeAdvisor ]);

  const handleBodyToggle = useCallback(() => {
    const currAdvisor = advisorRefs.current[activeAdvisor];
    gsap.to(currAdvisor, { duration: 0.25, paddingBottom: 0 });

    setActiveAdvisor(null);
  }, [ activeAdvisor ]);

  const {
    section_title,
    section_body_text
  } = primary;

  const advisorItemsRendered = useMemo(() => {
    if (!items || !items.length) return null;

    return items.map((item, i) => {
      const {
        advisor_portrait,
        advisor_name,
        advisor_information
      } = item;

      return (
        <div
          key={`advisor-${i}`}
          className={`advisor-grid-item ${activeAdvisor === i ? 'active' : ''}`}
          ref={(el) => advisorRefs.current.push(el)}
        >
          <div
            className="arrow-portrait-container"
            role="button"
            tabIndex="0"
            onClick={() => handleAdvisorClick(i)}
            onKeyDown={() => handleAdvisorClick(i)}
            aria-label="Show Advisor Content"
          >
            <div className="portrait-container">
              <BackgroundImage image={advisor_portrait} />
            </div>
            <ArrowFlag />
          </div>
          <div
            className={`grid-item-body ${i === activeAdvisor ? 'active' : ''}`}
            ref={(el) => bodyRefs.current.push(el)}
          >
            <div className="grid-item-body__inner container">
              <div className="body-content">
                <h4 className="h4 advisor-name">{advisor_name.text}</h4>
                <div
                  className="advisor-info"
                  dangerouslySetInnerHTML={{ __html: advisor_information.html }}
                />
                <div
                  className="body-toggle"
                  onClick={handleBodyToggle}
                >
                  <IconClose />
                </div>
              </div>
              <div className="body-portrait">
                <BackgroundImage image={advisor_portrait} />
              </div>
            </div>
          </div>
        </div>
      );
    });
  }, [ items, advisorRefs, bodyRefs, activeAdvisor, handleAdvisorClick, handleBodyToggle ]);

  if (hideForVisibility(auth, primary)) return null;

  return (
    <section className="slice-module advisors-grid">
      <div className="advisors-grid__inner container">
        <div className="section-intro">
          <h3 className="h3 section-title">{section_title.text}</h3>
          <div
            className="intro-body"
            dangerouslySetInnerHTML={{ __html: section_body_text.html }}
          />
        </div>
        <div className="grid">
          { advisorItemsRendered }
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
fragment AdvisorsGridFields on PrismicPageDataBodyAdvisorsGrid {
  id
  slice_type

  primary {
    visibility
    section_title {
      text
    }
    section_body_text {
      html
    }
  }

  items {
    advisor_portrait {
      gatsbyImageData
    }
    advisor_name {
      text
    }
    advisor_information {
      html
    }
  }
}
`;
