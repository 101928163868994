import React from 'react';
import InteractiveFeature from './interactive-feature';
import './styles.scss';
import { graphql } from 'gatsby';

export function SliceInteractivePoseFeature ({ primary, items }) {
  const {
    section_title,
    section_intro_text
  } = primary;

  return (
    <section className="slice-module interactive-pose-feature">
      <div className="interactive-pose-feature__inner container">
        { section_title?.text || section_intro_text?.html ? (
          <div className="intro-container">
            {
              section_title?.text ? (
                <h3 className="h3 section-title">{section_title.text}</h3>
              ) : null
            }
            {
              section_intro_text?.html ? (
                <div className="section-intro"
                  dangerouslySetInnerHTML={{ __html: section_intro_text.html }}
                />
              ) : null
            }
          </div>
        ) : null }
        <InteractiveFeature
          items={items}
        />
      </div>
    </section>
  );
}

export const query = graphql`
fragment InteractivePoseFeatureFields on PrismicPageDataBodyInteractivePoseFeature {
  id
  slice_type

  primary {
    section_title {
      text
    }
    section_intro_text {
      html
    }
  }

  items {
    pose_variation {
      document {
        ... on PrismicPose {
          _previewable
          id
          data {
            pose {
              category
              illustrations {
                adam { file { url } }
                antonio { file { url } }
                bob { file { url } }
                curt { file { url } }
                diego { file { url } }
                dinah { file { url } }
                elena { file { url } }
                emi { file { url } }
                fatima { file { url } }
                heidi { file { url } }
                isha { file { url } }
                jaden { file { url } }
                jax { file { url } }
                kyung { file { url } }
                levi { file { url } }
                lin { file { url } }
                naomi { file { url } }
                natasha { file { url } }
                nora { file { url } }
                piolo { file { url } }
                ravi { file { url } }
                rosa { file { url } }
                silvia { file { url } }
                suli { file { url } }
                tamika { file { url } }
                willow { file { url } }
                wolf { file { url } }
                yasmine { file { url } }
              }
              name
              nameSanskrit
              props
              tiytRatingNotes
              tiytScale
              mitigationStrategies
              instructionalText
              increasingPotentialReactions
              affirmation
              mudra
              pranayama
              nameSymbolism
            }
          }
        }
      }
    }
  }
}
`;
