import React, { useMemo } from 'react';
import ArticleMediaItem from './article-media-item';
import { graphql } from 'gatsby';

export function SliceArticleMedia ({ items }) {
  const mediaItemsRendered = useMemo(() => {
    if (!items || !items.length) return null;

    return items.map((item, i) => {
      return (
        <ArticleMediaItem
          key={`article-media-item-${i}`}
          data={item}
        />
      );
    });
  }, [ items ]);

  return (
    <section className="article-slice article-media">
      {mediaItemsRendered}
    </section>
  );
}

export const query = graphql`
fragment ArticleMediaFields on PrismicArticleDataBodyArticleVideo {
  id
  slice_type

  items {
    divider_above
    divider_below
    embed_caption {
      html
    }
    embed_code {
      text
    }
    image {
      gatsbyImageData
    }
  }
}

fragment PoseArticleMediaFields on PrismicPoseDataBodyArticleVideo {
  id
  slice_type

  items {
    divider_above
    divider_below
    embed_caption {
      html
    }
    embed_code {
      text
    }
    image {
      gatsbyImageData
    }
  }
}
`;
