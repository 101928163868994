import React, { useMemo } from 'react';
import ArticleContentItem from './article-content-item';
import { graphql } from 'gatsby';
import './styles.scss';

export function SliceArticleContent ({ items }) {
  const itemsRendered = useMemo(() => {
    if (!items || !items.length) return null;

    return items.map((item, i) => {
      return (
        <ArticleContentItem
          key={`article-content-item-${i}`}
          data={item}
        />
      );
    });
  }, [ items ]);

  return (
    <section className="article-slice article-content">
      { itemsRendered }
    </section>
  );
}

export const query = graphql`
fragment ArticleContentFields on PrismicArticleDataBodyArticleContent {
  id
  slice_type
  items {
    content {
      html
    }
    divider_above
    divider_below
    image {
      gatsbyImageData
      alt
    }
    image_configuration
  }
}

fragment PoseArticleContentFields on PrismicPoseDataBodyArticleContent {
  id
  slice_type
  items {
    content {
      html
    }
    divider_above
    divider_below
    image {
      gatsbyImageData
      alt
    }
    image_configuration
  }
}
`;
