import React, { useMemo, useContext } from 'react';
import { graphql } from 'gatsby';
import './styles.scss';
import { Img } from '../../../image';
import { TextButton } from '../../../buttons';
import { AuthContext, hideForVisibility } from '../../../auth';

export const SliceThreeColCTA = ({ primary, items }) => {
  const auth = useContext(AuthContext);

  const itemsRendered = useMemo(() => {
    if (!items || !items.length) return null;

    return items.map((item, i) => {
      const {
        column_image,
        column_title,
        column_body,
        cta_text,
        cta_url,
        divider_line_color
      } = item;

      return (
        <div
          className="three-col-cta__column"
          key={`three-col-col-${i}`}
        >
          <div className="image-container">
            <Img image={column_image} objectFit="contain" />
          </div>
          <h2 className="h2">{column_title.text}</h2>
          <div className={`centered divider divider-${divider_line_color.toLowerCase()}`} />
          <div
            className="col-body"
            dangerouslySetInnerHTML={{ __html: column_body.html }}
          />
          {
            cta_url?.url ? (
              <TextButton to={cta_url.url}>
                {cta_text.text}
              </TextButton>
            ) : null
          }
        </div>
      );
    });
  }, [ items ]);

  if (hideForVisibility(auth, primary)) return null;

  return (
    <section className="slice-module three-col-cta">
      <div className="three-col-cta__inner container">
        {itemsRendered}
      </div>
    </section>
  );
};

export const query = graphql`
fragment ThreeColCtaFields on PrismicPageDataBodyThreeColCta {
  id
  slice_type

  primary { visibility }

  items {
    column_image {
      gatsbyImageData
    }
    column_title {
      text
    }
    column_body {
      html
    }
    cta_text {
      text
    }
    cta_url {
      url
    }
    divider_line_color
  }
}

fragment ArticleThreeColCtaFields on PrismicArticleDataBody1ThreeColCta {
  id
  slice_type

  items {
    column_image {
      gatsbyImageData
    }
    column_title {
      text
    }
    column_body {
      html
    }
    cta_text {
      text
    }
    cta_url {
      url
    }
    divider_line_color
  }
}

fragment PoseThreeColCtaFields on PrismicPoseDataBody1ThreeColCta {
  id
  slice_type

  items {
    column_image {
      gatsbyImageData
    }
    column_title {
      text
    }
    column_body {
      html
    }
    cta_text {
      text
    }
    cta_url {
      url
    }
    divider_line_color
  }
}
`;
