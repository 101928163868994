import React, { useContext } from 'react';
import { graphql } from 'gatsby';
import { AuthContext, hideForVisibility } from '../../../auth';
import './styles.scss';

export function SliceVideoEmbed ({ primary }) {
  const auth = useContext(AuthContext);

  if (hideForVisibility(auth, primary)) return null;

  const { video_iframe } = primary;

  return (
    <section className="slice-module video-embed">
      <div className="video-embed__inner container">
        <div className="video-container">
          <div className="player-wrapper" dangerouslySetInnerHTML={{ __html: video_iframe.text }} />
        </div>
      </div>
    </section>
  );
}

export const query = graphql`
fragment VideoEmbedFields on PrismicPageDataBodyVideoEmbed {
  id
  slice_type

  primary {
    visibility
    video_iframe {
      text
    }
  }
}
`;
