import { graphql } from 'gatsby';
import React, { useMemo } from 'react';
import { Img } from '../../../image';
import './styles.scss';

export function SubscriptionImageText ({ primary }) {
  const text = useMemo(() => {
    if (!primary?.content?.html) return null;

    return (
      <div className="text" dangerouslySetInnerHTML={{
        __html: primary.content.html
      }} />
    );
  }, [ primary.content ]);

  const image = useMemo(() => {
    if (!primary?.image?.gatsbyImageData) return null;

    return (
      <Img image={primary.image.gatsbyImageData} />
    );
  }, [ primary.image ]);

  const classes = [
    'sub-module',
    'image-text',
    `alignment-${primary.image_alignment.toLowerCase()}`
  ].join(' ');

  return (
    <div className={classes}>
      { text }
      { image }
    </div>
  );
}

export const query = graphql`
fragment SubscriptionImageAndTextFields on PrismicSubscriptionDataBodyImageAndText {
  id
  primary {
    image {
      alt
      gatsbyImageData
    }
    content {
      html
    }
    image_alignment
  }
  slice_type
}
`;
