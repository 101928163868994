/*globals process*/
/*eslint-disable react/jsx-no-target-blank*/
import React, { useMemo, useState, useContext } from 'react';
import { IconChevronLeft, IconChevronRight, IconSubscriberGate } from '../../../icons';
import { TextButton } from '../../../buttons';
// import { InfoTooltip } from '../../../info-tooltip';
import InfoIcon from '../../../../images/icon-info.svg';
import { Remark } from 'react-remark';
import LinSVG from '../../../../images/L_Bound_Angle_Pose_SIMPLIFIED.svg';
import { Link, graphql, useStaticQuery } from 'gatsby';
import useMergePrismicPreviewData from '../../../../utils/use-merge-prismic-preview-data-fix';
import { AuthContext } from '../../../auth';

function fixProdEnv (url) {
  if (!process.env.GATSBY_PROD_ENV) return url;
  return url.replace('.yogatherapylibrary.com',
    `.${process.env.GATSBY_PROD_ENV}.yogatherapylibrary.com`);
}

export default function InteractiveFeature ({ items, featuredPose, pageType }) {
  const auth = useContext(AuthContext);
  const [ activePoseIndex, setActivePoseIndex ] = useState(0);
  const [ activeAvatar, setActiveAvatar ] = useState(0);

  const _data = useStaticQuery(graphql`
  query CategoryArticlesQuery {
    allPrismicArticle(filter: {url: {glob: "/resources/category-*"}}) {
      nodes {
        _previewable
        url
      }
    }

    allRedirectNode {
      nodes { fromPath }
    }

    prismicGlobalOptions {
      _previewable
      data {
        tiyt_info_tooltip { html }
        potential_reactions_tooltip_link { url }
      }
    }

    prismicAvatars {
      data {
        avatars {
          name, live
        }
      }
    }

  }`);

  const data = useMergePrismicPreviewData(_data);

  const avatars = data.prismicAvatars.data.avatars.filter(a => a.live).map((a) => {
    return a.name.toLowerCase();
  });

  const existingCategoryRedirectUrls = data.allRedirectNode.nodes
    .filter(n => n.fromPath.startsWith('/resources/category-'))
    .map(n => n.fromPath.replace(/\/$/, ''));

  const existingCategoryArticleUrls = new Set(
    data.allPrismicArticle.nodes.map(n => n.url).concat(existingCategoryRedirectUrls)
  );

  let mainPose = featuredPose;

  if (!mainPose) {
    mainPose = items[activePoseIndex]?.pose_variation?.document?.data?.pose;
  }

  const tiytInfoTooltip = data.prismicGlobalOptions.data.tiyt_info_tooltip;
  const tiytTooltipLink = data.prismicGlobalOptions.data.potential_reactions_tooltip_link;

  const {
    category,
    illustrations,
    name: poseName,
    nameSanskrit,
    props,
    tiytRatingNotes,
    tiytScale,
    mitigationStrategies,
    instructionalText,
    increasingPotentialReactions,
    affirmation,
    mudra,
    pranayama,
    nameSymbolism,
    drishti
  } = mainPose;

  let currentPoseImage = illustrations[avatars[activeAvatar]]?.file?.url;

  if (!currentPoseImage) {
    currentPoseImage = illustrations['tamika'].file.url;
  }

  const findNextAvatar = (dir, currAvatar) => {
    let nextExistingAvatar;

    const clonedAvatars = JSON.parse(JSON.stringify(avatars));

    if (dir === 'next') {
      let sliceStart = !currAvatar ? 1 : currAvatar === avatars.length - 1 ? 0 : currAvatar + 1;
      let shiftedAvatars = [
        ...avatars.slice(sliceStart),
        ...avatars.slice(0, sliceStart)
      ];

      let firstMatch = shiftedAvatars.find(x => !!illustrations[x]?.file?.url);
      nextExistingAvatar = avatars.findIndex(x => x === firstMatch);
    } else if (dir === 'prev') {
      const sliceStart = currAvatar;
      const shiftedAvatars = !currAvatar ? clonedAvatars.reverse() : [
        ...clonedAvatars.slice(0, sliceStart).reverse(),
        ...clonedAvatars.slice(sliceStart + 1).reverse()
      ];

      const firstMatch = shiftedAvatars.find(x => !!illustrations[x]?.file?.url);
      nextExistingAvatar = avatars.findIndex(x => x === firstMatch);
    }

    return nextExistingAvatar;
  };

  const handleAvatarChange = (evOrDir) => {
    if (evOrDir?.target) {
      const { left, width } = evOrDir.target.getBoundingClientRect();

      if ( (evOrDir.clientX - left) / width < 0.5) {
        return handleAvatarChange('prev');
      }

      return handleAvatarChange('next');
    }

    const nextAvatar = findNextAvatar(evOrDir, activeAvatar);
    setActiveAvatar(nextAvatar);
  };

  const variationsRendered = useMemo(() => {
    if (!items || !items.length) return null;

    const handleVariationClick = (index) => {
      setActivePoseIndex(index);
    };

    return items.map((item, i) => {
      if (i === activePoseIndex && !pageType) return null;

      let pose = '';
      if (pageType === 'pose') {
        pose = item;
      } else {
        pose = item.pose_variation?.document?.data?.pose;
      }

      if (!pose?.illustrations?.tamika) return null;

      const {
        name: variationName,
        illustrations: variationIllustrations,
        id
      } = pose;

      let variationImage = variationIllustrations[avatars[activeAvatar]]?.file?.url;

      if (!variationImage) {
        variationImage = variationIllustrations['tamika'].file.url;
      }

      if (pageType === 'pose') {
        return (
          <Link to={`/poses/${id.toLowerCase()}`} className="variation" key={`variation-${i}`}>
            <div className="img-container">
              <img src={variationImage} />
            </div>
            <h5 className="variation-title">{variationName}</h5>
          </Link>
        );
      }

      return (
        <div
          className="variation"
          key={`variation-${i}`}
          role="button"
          tabIndex="4"
          onClick={() => handleVariationClick(i)}
          aria-label="Activate Pose Variation"
        >
          <div className="img-container">
            <img src={variationImage} />
          </div>
          <h5 className="variation-title">{variationName}</h5>
        </div>
      );
    });
  }, [ items, activePoseIndex, activeAvatar, pageType ]);

  let backButton = null;

  if (pageType === 'pose') {
    backButton = (
      <a onClick={() => history.back()} className="back-arrow">‹</a>
    );
  }

  if (pageType === 'pose' && !auth.subscription?.active) {
    return (
      <div className="interactive-feature">
        <div className="left-col">
          <div className="pose-image-container">
            <div className="pose-img">
            </div>
          </div>
        </div>
        <div className="right-col">
          <h3 className="h3 pose-name">
            {backButton}{poseName}
          </h3>
          <div className="extra-info">
            <p>Loading... Please wait.</p>
          </div>
        </div>
      </div>
    );
  }

  const detailList = [];

  if (mudra) {
    detailList.push(
      <li key={0}>
        <h6 className="h6 pose-detail-title">Mudra</h6>
        <span className="pose-detail"><Remark>{mudra}</Remark></span>
      </li>
    );
  }

  if (pranayama) {
    detailList.push(
      <li key={1}>
        <h6 className="h6 pose-detail-title">Pranayama</h6>
        <span className="pose-detail"><Remark>{pranayama}</Remark></span>
      </li>
    );
  }

  const extraInfoRendered = [];

  if (nameSymbolism) {
    extraInfoRendered.push(
      <div className="extra-info" key={0}>
        <h6 className="h6">Name Symbolism</h6>
        <Remark>{nameSymbolism}</Remark>
      </div>
    );
  }

  if (affirmation) {
    extraInfoRendered.push(
      <div className="extra-info" key={1}>
        <h6 className="h6">Affirmation</h6>
        <Remark>{affirmation}</Remark>
      </div>
    );
  }

  if (tiytRatingNotes) {
    extraInfoRendered.push(
      <div className="extra-info" key={2}>
        <h6 className="h6">
          Potential Reaction Scale Rationale
          <Link to={tiytTooltipLink?.url} className="ytl-info-tooltip-icon">
            <img src={InfoIcon} alt={tiytInfoTooltip?.text ?? ''} />
          </Link>
          {/*
          <InfoTooltip iconAlt="More information" href={tiytTooltipLink?.url}>
            <div
              onClick={handleTooltipClick}
              dangerouslySetInnerHTML={{ __html: tiytInfoTooltip?.html }}
            />
          </InfoTooltip>
          */}
        </h6>
        <Remark>{tiytRatingNotes}</Remark>
      </div>
    );
  }

  if (mitigationStrategies) {
    extraInfoRendered.push(
      <div className="extra-info" key={3}>
        <h6 className="h6">Mitigation Strategies</h6>
        <Remark>{mitigationStrategies}</Remark>
      </div>
    );
  }

  if (increasingPotentialReactions) {
    extraInfoRendered.push(
      <div className="extra-info" key={4}>
        <h6 className="h6">May Increase Potential Reaction</h6>
        <Remark>{increasingPotentialReactions}</Remark>
      </div>
    );
  }

  if (instructionalText) {
    extraInfoRendered.push(
      <div className="extra-info" key={5}>
        <h6 className="h6">Suggested Cueing Text</h6>
        <p>{instructionalText}</p>
      </div>
    );
  }

  if (drishti) {
    extraInfoRendered.push(
      <div className="extra-info" key={6}>
        <h6 className="h6">Dṛṣṭi - Drishti</h6>
        <Remark>{drishti}</Remark>
      </div>
    );
  }

  const prScale = !tiytScale ? null : (
    <div className="tiyt-scale">
      <span className="tiyt-digit">{tiytScale}</span>
      <div className={`tiyt-scale-bar --scale-${tiytScale}`}>
        <span className="dot" />
      </div>
      <span className="tiyt-label">PR Scale</span>
    </div>
  );

  return (
    <div className="interactive-feature">
      <div className="left-col">
        <div className="pose-image-container">
          <div className="pose-img">
            <img src={currentPoseImage} />
          </div>
          <div className="avatar-controller" onClick={handleAvatarChange}>
            <img src={LinSVG} />
            <div className="controls">
              <div
                className="prev-avatar"
                role="button"
                tabIndex="4"
                onClick={() => handleAvatarChange('prev')}
                onKeyDown={() => handleAvatarChange('prev')}
                aria-label="Show previous avatar"
              >
                <IconChevronLeft />
              </div>
              <span className="tiyt-label">Avatar</span>
              <div
                className="next-avatar"
                role="button"
                tabIndex="4"
                onClick={() => handleAvatarChange('next')}
                onKeyDown={() => handleAvatarChange('next')}
                aria-label="Show next avatar"
              >
                <IconChevronRight />
              </div>
            </div>
          </div>
          {prScale}
        </div>

        <div className="variations-container --above-mobile">
          <h6 className="h6">Variations</h6>
          <div className="variations-list">
            { variationsRendered }
          </div>
        </div>
      </div>

      <div className="right-col">
        <h3 className="h3 pose-name">
          {backButton}{poseName}
        </h3>
        <ul className="pose-info">
          <li>
            <h6 className="h6 pose-detail-title">Sanskrit</h6>
            <span className="pose-detail">{nameSanskrit}</span>
          </li>

          <li>
            <h6 className="h6 pose-detail-title">Category</h6>
            <ul className="detail-list">
              { category.map((cat, i) => {
                const to = `/resources/category-${cat.replace(/[^a-z0-9]+/ig, '-').toLowerCase()}`;

                if (existingCategoryArticleUrls.has(to)) {
                  return (
                    <li key={`pose-cat-${i}`} className="pose-detail">
                      <a href={to} target="_blank" >{cat}</a>
                    </li>
                  );
                }

                return ( <li key={`pose-cat-${i}`} className="pose-detail">{cat}</li> );
              }) }
            </ul>
          </li>

          <li>
            <h6 className="h6 pose-detail-title">Props</h6>
            <ul className="detail-list">
              {
                props && props.length ? props.map((prop, i) => {
                  return (
                    <li className="pose-detail" key={`pose-prop-${i}`}>{prop}</li>
                  );
                }) : <li className="pose-detail">None</li>
              }
            </ul>
          </li>

          {detailList}
        </ul>

        <div className="variations-container --mobile">
          <h6 className="h6">Variations</h6>
          <div className="variations-list">
            { variationsRendered }
          </div>
        </div>

        {extraInfoRendered}

      </div>
    </div>
  );
}
