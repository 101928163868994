import React, { useMemo } from 'react';
import { Img } from '../../../image';
import { LinkWrapper } from '../../../link-wrapper';
import { graphql } from 'gatsby';
import './styles.scss';

export function SliceArticleDownloads ({ items, primary }) {
  const { header } = primary;

  const itemsRendered = useMemo(() => {
    if (!items || !items.length) return null;

    return items.map((item, i) => {
      const { icon, link } = item;
      const { url, size, raw } = link;

      const mbSize = (size / 1000000).toFixed(2);

      return (
        <div
          className="download-item"
          key={`download-item-${i}`}
        >
          <Img image={icon} />
          <div className="content">
            <LinkWrapper url={url} target="_blank">
              { raw.name }
            </LinkWrapper>
            <span className="download-size">{mbSize} MB</span>
          </div>
        </div>
      );
    });
  }, [ items ]);

  return (
    <section className="article-slice article-downloads">
      <h4 className="h4 section-header">{header.text}</h4>
      <div className="downloads">
        {itemsRendered}
      </div>
    </section>
  );
}

export const query = graphql`
fragment ArticleDownloadsFields on PrismicArticleDataBodyArticleDownloads {
  id
  slice_type

  items {
    icon {
      gatsbyImageData
    }
    link {
      raw, size, url
    }
  }
  primary {
    header {
      text
    }
  }
}

fragment PoseArticleDownloadsFields on PrismicPoseDataBodyArticleDownloads {
  id
  slice_type

  items {
    icon {
      gatsbyImageData
    }
    link {
      raw, size, url
    }
  }
  primary {
    header {
      text
    }
  }
}
`;
