import React, { useContext } from 'react';
import { Img } from '../../../image';
import { graphql } from 'gatsby';
import { AuthContext, hideForVisibility } from '../../../auth';
import './styles.scss';

export const SliceFeaturedTestimonial = ({ primary }) => {
  const auth = useContext(AuthContext);

  if (hideForVisibility(auth, primary)) return null;

  const {
    testimonial_image,
    quote_text,
    quote_attribution,
    quote_attribution_title,
    quote_on_left
  } = primary;

  return (
    <section className="slice-module featured-testimonial">
      <div className={`featured-testimonial__inner container ${quote_on_left ? 'reversed' : ''}`}>
        <div className="image-container">
          <Img image={testimonial_image} />
        </div>

        <blockquote className="featured-quote">
          <div
            className="quote-text"
            dangerouslySetInnerHTML={{ __html: quote_text.html }}
          />
          <div className="quote-attr-container">
            <span className="attr">&mdash; { quote_attribution.text} </span>
            {
              quote_attribution_title?.text ? (
                <span className="attr-title">, {quote_attribution_title.text}</span>
              ) : null
            }
          </div>
        </blockquote>
      </div>
    </section>
  );
};

export const query = graphql`
fragment FeaturedTestimonialFields on PrismicPageDataBodyFeaturedTestimonial {
  id
  slice_type

  primary {
    visibility
    quote_on_left
    testimonial_image {
      gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
    }
    quote_text {
      html
    }
    quote_attribution {
      text
    }
    quote_attribution_title {
      text
    }
  }
}
fragment ArticleFeaturedTestimonialFields on PrismicArticleDataBody1FeaturedTestimonial {
  id
  slice_type

  primary {
    quote_on_left
    testimonial_image {
      gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
    }
    quote_text {
      html
    }
    quote_attribution {
      text
    }
    quote_attribution_title {
      text
    }
  }
}
fragment PoseFeaturedTestimonialFields on PrismicPoseDataBody1FeaturedTestimonial {
  id
  slice_type

  primary {
    quote_on_left
    testimonial_image {
      gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
    }
    quote_text {
      html
    }
    quote_attribution {
      text
    }
    quote_attribution_title {
      text
    }
  }
}
`;
