import React, { useContext } from 'react';
import { Img } from '../../../image';
import { graphql } from 'gatsby';
import './styles.scss';
import { TextButton } from '../../../buttons';
import { AuthContext } from '../../../auth';

export function SliceComingSoonArticle ({ primary }) {
  const auth = useContext(AuthContext);

  const {
    visibility,
    section_subtitle,
    section_title,
    read_time,
    cta_text,
    cta_link,
    featured_image,
    body_text,
    green_background_stripe,
    text_on_right
  } = primary;

  if (visibility === 'Show to logged-in users only' && !auth.userData) {
    return null;
  }

  if (visibility === 'Show to anonymous users only' && auth.userData) {
    return null;
  }

  const sectionClasses = [
    'slice-module',
    'coming-soon-article',
    green_background_stripe ? 'green-bg-stripe' : '',
    text_on_right ? 'text-on-right' : ''
  ];

  return (
    <section className={sectionClasses.join(' ')}>
      <div className="coming-soon-article__inner container">
        <div className="image-container">
          <Img image={featured_image} />
        </div>
        <div className="body-content">
          <div className="subtitle-content">
            <span className="subtitle">{section_subtitle.text}</span>
            <span className="readtime">{read_time.text}</span>
          </div>
          <h3 className="article-title">{ section_title.text }</h3>
          <div
            className="body-text"
            dangerouslySetInnerHTML={{ __html: body_text.html }}
          />
          <span className="readtime --above-mobile">{read_time.text}</span>
          <TextButton
            inverted={green_background_stripe}
            to={cta_link.url}
          >
            {cta_text.text}
          </TextButton>
        </div>
      </div>
    </section>
  );
}

export const query = graphql`
fragment ComingSoonArticleFields on PrismicPageDataBodyComingSoonArticleFeature {
  id
  slice_type

  primary {
    visibility
    text_on_right
    green_background_stripe
    body_text {
      html
    }
    section_subtitle {
      text
    }
    section_title {
      text
    }
    read_time {
      text
    }
    cta_text {
      text
    }
    cta_link {
      url
    }
    featured_image {
      gatsbyImageData
    }
  }
}
fragment ArticleComingSoonArticleFields on PrismicArticleDataBody1ComingSoonArticleFeature {
  id
  slice_type

  primary {
    text_on_right
    green_background_stripe
    body_text {
      html
    }
    section_subtitle {
      text
    }
    section_title {
      text
    }
    read_time {
      text
    }
    cta_text {
      text
    }
    cta_link {
      url
    }
    featured_image {
      gatsbyImageData
    }
  }
}
fragment PoseComingSoonArticleFields on PrismicPoseDataBody1ComingSoonArticleFeature {
  id
  slice_type

  primary {
    text_on_right
    green_background_stripe
    body_text {
      html
    }
    section_subtitle {
      text
    }
    section_title {
      text
    }
    read_time {
      text
    }
    cta_text {
      text
    }
    cta_link {
      url
    }
    featured_image {
      gatsbyImageData
    }
  }
}
`;
