import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BackgroundImage, Img } from '../../../image';
import { useInterval } from '../../../../common/use-interval';
import { graphql } from 'gatsby';
import './styles.scss';

export const SliceHeroCarousel = ({ primary, items }) => {
  const [ activeSlide, setActiveSlide ] = useState(0);
  const [ slideTime, setSlideTime ] = useState(0);
  const [ playing, setPlaying ] = useState(true);

  useEffect(() => {
    setSlideTime(0);
  }, [ activeSlide ]);

  const {
    background_image,
    big_text,
    overflow_images,
    mobile_foreground_image,
    height
  } = primary;

  const itemsRendered = useMemo(() => {
    if (!items || !items.length) return null;

    return items.map((item, i) => {
      const { pose_image } = item;

      return (
        <div
          key={`hero-carousel-item-${i}`}
          className={`hero-carousel-item ${activeSlide === i ? 'active' : ''}`}
        >
          <Img image={pose_image} objectFit="contain" loading={i === 0 ? 'eager' : 'lazy'} />
        </div>
      );
    });
  }, [ items, activeSlide ]);

  const handleSlideChange = useCallback((dir, pause = true) => {
    if (dir === 'prev') {
      if (!activeSlide) {
        setActiveSlide(items.length - 1);
      } else {
        setActiveSlide(activeSlide - 1);
      }
    } else {
      if (activeSlide === items.length - 1) {
        setActiveSlide(0);
      } else {
        setActiveSlide(activeSlide + 1);
      }
    }

    if (pause) {
      setPlaying(false);
    }
  }, [ activeSlide, items.length ]);

  const handleJumpToSlide = (index) => {
    setActiveSlide(index);
    setPlaying(false);
  };

  const dotsRendered = useMemo(() => {
    if (!items || !items.length || items.length < 2) return null;

    return items.map((item, i) => {
      return (
        <div
          className={`slider-dot ${activeSlide === i ? 'active' : ''}`}
          role="button"
          tabIndex="0"
          onClick={() => handleJumpToSlide(i)}
          onKeyDown={() => handleJumpToSlide(i)}
          key={`slider-dot-${i}`}
          aria-label={`Jump to Slide ${i}`}
        />
      );
    });
  }, [ items, activeSlide ]);

  useInterval(() => {
    if (slideTime < 3) {
      setSlideTime(slideTime + 1);
    } else {
      handleSlideChange('next', false);
    }
  }, playing ? 1000 : null);

  const prevArrow = useMemo(() => {
    if (!items || !items.length || items.length < 2) return null;

    return (
      <div
        className="prev-arrow"
        role="button"
        tabIndex="0"
        onClick={() => handleSlideChange('prev')}
        onKeyDown={() => handleSlideChange('prev')}
        aria-label="Show Previous Slide"
      />
    );
  }, [ items, handleSlideChange ]);

  const nextArrow = useMemo(() => {
    if (!items || !items.length || items.length < 2) return null;

    return (
      <div
        className="next-arrow"
        role="button"
        tabIndex="0"
        onClick={() => handleSlideChange('next')}
        onKeyDown={() => handleSlideChange('next')}
        aria-label="Show Next Slide"
      />
    );
  }, [ items, handleSlideChange ]);

  let className = `slice-module hero-carousel ${overflow_images ? 'overflow-images' : ''}`;

  if (height) {
    className += ' --short';
  }

  return (
    <section className={className}>
      <BackgroundImage image={background_image} loading="eager" />
      <div className="hero-carousel__inner container">
        {
          big_text?.text ? (
            <div className="hero-carousel-body">
              <h1 className="big-text">{big_text.text}</h1>
            </div>
          ) : null
        }
        {
          mobile_foreground_image?.gatsbyImageData ? (
            <div className="mobile-foreground">
              <Img
                image={mobile_foreground_image}
                objectFit="contain"
                objectPosition={big_text?.text ? "100% 50%" : '50% 50%'}
                loading="eager"
              />
            </div>
          ) : null
        }
        {
          items.length ? (
            <>
              {prevArrow}
              <div
                className="hero-carousel-slider"
              >
                {itemsRendered}
              </div>
              {nextArrow}
              <div className="dots">
                { dotsRendered }
              </div>
            </>
          ) : null
        }
      </div>
    </section>
  );
};

export const query = graphql`
fragment HeroCarouselFields on PrismicPageDataBodyHeroCarousel {
  id
  slice_type

  items {
    pose_image {
      gatsbyImageData
    }
  }

  primary {
    overflow_images
    height
    mobile_foreground_image {
      gatsbyImageData
    }
    background_image {
      gatsbyImageData
    }
    big_text {
      text
    }
  }
}
`;
