import React, { useContext, useMemo, useState } from 'react';
import { graphql } from 'gatsby';
import SubscriptionColumn from './subscription-column';
import { AuthContext, hideForVisibility } from '../../../auth';
import { CalloutArrowIcon, CalloutArrowIconMobile, IconChevronLeftSub, IconChevronRightSub } from '../../../icons';
import './styles.scss';

export const SliceSubscriptionPricing2 = ({ items, primary }) => {
  const auth = useContext(AuthContext);
  const [ pricingModel, setPricingModel ] = useState('monthly');
  const [ activeMobilePlan, setActiveMobilePlan ] = useState(0);

  const {
    section_title,
    monthly_label,
    legal_text,
    yearly_label,
    yearly_savings_callout
  } = primary;

  const pricingModelSwitch = useMemo(() => {
    const classes = [
      'pricing-model-switch',
      `${pricingModel}-active`
    ].join(' ');

    function handleClick (model) {
      if (pricingModel !== model) {
        setPricingModel(model);
      }
    }

    return (
      <div className={classes}>
        <button
          className="button-monthly"
          onClick={() => handleClick('monthly')}
        >
          {monthly_label}
        </button>
        <button
          className="button-yearly"
          onClick={() => handleClick('yearly')}
        >
          {yearly_label}
        </button>
        <div className="callout">
          <CalloutArrowIcon className="svg-desktop" />
          <CalloutArrowIconMobile className="svg-mobile" />
          <div className="callout-text" dangerouslySetInnerHTML={{ __html: yearly_savings_callout.html }} />
        </div>
      </div>
    );
  }, [ pricingModel, monthly_label, yearly_label, yearly_savings_callout ]);

  const subColumns = useMemo(() => {
    if (!items?.length) return null;

    const subCols = items.map((item, i) => {
      const { document: itemDoc } = item.subscription;
      const { data, id } = itemDoc;

      return (
        <SubscriptionColumn
          key={id}
          data={data}
          pricingModel={pricingModel}
          activeOnMobile={activeMobilePlan === i}
          popular={!!i}
        />
      );
    });

    const classes = [
      'tiers-track',
      `mobile-active-${activeMobilePlan}`
    ].join(' ');

    return (
      <div className={classes}>
        {subCols}
      </div>
    );
  }, [ items, pricingModel, activeMobilePlan ]);

  const mobileChoosePlan = useMemo(() => {
    function handleClick (newPlan) {
      setActiveMobilePlan(newPlan);
    }

    const trackClasses = [
      'button-track',
      `active-${activeMobilePlan}`
    ].join(' ');

    return (
      <div className="mobile-plan-selector">
        <h6>Choose Your Plan</h6>
        <div className={trackClasses}>
          <button
            onClick={() => handleClick(1)}
          >
            <div className="button-inner">
              <span>Premium</span>
              <IconChevronRightSub />
            </div>
          </button>
          <button
            onClick={() => handleClick(0)}
          >
            <div className="button-inner">
              <IconChevronLeftSub />
              <span>Standard</span>
            </div>
          </button>
        </div>
      </div>
    );
  }, [ activeMobilePlan ]);

  if (hideForVisibility(auth, primary)) return null;

  return (
    <section className="slice-module subscription-pricing-2">
      <div className="subscription-pricing__bg" />
      <div className="module-heading">
        <h2 className="subscription-pricing-heading">{section_title.text}</h2>
        {pricingModelSwitch}
      </div>
      <div className="subscription-pricing-2__inner container">
        {mobileChoosePlan}
        <div className="tiers-container">
          {subColumns}
        </div>
        <div className="legal-text"
          dangerouslySetInnerHTML={{ __html: legal_text.html }}
        />
      </div>
    </section>
  );
};

export const query = graphql`
fragment SubscriptionPricing2Fields on PrismicPageDataBodySubscriptionPricing2 {
  id
  slice_type
  primary {
    section_title {
      text
    }
    monthly_label
    legal_text {
      html
    }
    visibility
    yearly_label
    yearly_savings_callout {
      html
    }
  }
  items {
    subscription {
      document {
        ... on PrismicSubscription {
          id
          data {
            dev_monthly_paypal_plan_id
            dev_monthly_stripe_coupon_id
            dev_monthly_stripe_price_id
            dev_yearly_paypal_plan_id
            dev_yearly_stripe_coupon_id
            dev_yearly_stripe_price_id
            effective_monthly_price_billed_yearly
            monthly_paypal_plan_id
            monthly_stripe_coupon_id
            monthly_stripe_price_id
            name
            normal_effective_monthly_price_billed_yearly
            normal_price_per_month
            price_per_month
            price_per_year
            yearly_paypal_plan_id
            yearly_savings_line {
              html
            }
            monthly_cta_link {
              url
              target
            }
            cta_text
            yearly_cta_link {
              url
              target
            }
            yearly_stripe_coupon_id
            yearly_stripe_price_id
            body {
              ... on PrismicSubscriptionDataBodyFeaturesList {
                ... SubscriptionFeaturesListFields
              }
              ... on PrismicSubscriptionDataBodyImageAndText {
                ... SubscriptionImageAndTextFields
              }
            }
          }
        }
      }
    }
  }
}
`;
