/*eslint-env node*/
exports.linkResolver = function linkResolver (doc) {
  if (doc.uid === 'home' && doc.type === 'page') {
    return '/';
  }

  if (doc.type === 'article' && (doc.uid ?? doc.slug)) {
    return `/resources/${doc.uid ?? doc.slug}`;
  }

  if (doc.type === 'pose' && doc.slug) {
    const m = doc.slug.match(/-master-pose-(.+)$/);

    if (m) return `/poses/${m[1]}`;
  }

  if (doc.type === '404page') {
    return `/404`;
  }

  if (doc.uid ?? doc.slug) {
    return `/${doc.uid ?? doc.slug}`;
  }

  throw new Error(`Invalid type for link: ${JSON.stringify(doc)}`);
};
