import React, { useContext } from 'react';
import { graphql } from 'gatsby';
import './styles.scss';
import { Img } from '../../../image';
import { AuthContext, hideForVisibility } from '../../../auth';

export function SliceTwoColumnSection ({ primary }) {
  const auth = useContext(AuthContext);

  if (hideForVisibility(auth, primary)) return null;

  const {
    section_title,
    section_lead_in_text,
    section_body_text,
    text_on_right,
    section_image
  } = primary;

  const sectionClasses = [
    'two-column-section',
    'slice-module',
    text_on_right ? 'reversed-order' : ''
  ];

  return (
    <section className={sectionClasses.join(' ')}>
      <div className="two-column-section__inner container">
        <div className="body-container">
          <h3 className="h3 section-title">{section_title.text}</h3>
          {
            section_lead_in_text?.html ? (
              <div
                className="lead-in-text"
                dangerouslySetInnerHTML={{ __html: section_lead_in_text.html }}
              />
            ) : null
          }
          <div
            className="body-text"
            dangerouslySetInnerHTML={{ __html: section_body_text.html }}
          />
        </div>
        <div className="image-container">
          <Img image={section_image} />
        </div>
      </div>
    </section>
  );
}

export const query = graphql`
fragment TwoColumnSectionFields on PrismicPageDataBodyTwoColumnSection {
  id
  slice_type

  primary {
    visibility
    section_title {
      text
    }
    section_lead_in_text {
      html
    }
    section_body_text {
      html
    }
    text_on_right
    section_image {
      gatsbyImageData(placeholder: BLURRED)
    }
  }
}
fragment ArticleTwoColumnSectionFields on PrismicArticleDataBody1TwoColumnSection {
  id
  slice_type

  primary {
    section_title {
      text
    }
    section_lead_in_text {
      html
    }
    section_body_text {
      html
    }
    text_on_right
    section_image {
      gatsbyImageData(placeholder: BLURRED)
    }
  }
}
fragment PoseTwoColumnSectionFields on PrismicPoseDataBody1TwoColumnSection {
  id
  slice_type

  primary {
    section_title {
      text
    }
    section_lead_in_text {
      html
    }
    section_body_text {
      html
    }
    text_on_right
    section_image {
      gatsbyImageData(placeholder: BLURRED)
    }
  }
}
`;
