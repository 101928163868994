import React from 'react';
import { Img } from '../../../image';
import './styles.scss';

export default function ArticleMediaItem ({ data }) {
  const {
    divider_above,
    divider_below,
    embed_caption,
    embed_code,
    image
  } = data;

  const classes = [
    'article-media-item',
    divider_above ? '--divider-above' : '',
    divider_below ? '--divider-below' : ''
  ];

  return (
    <div className={classes.join(' ')}>
      {
        embed_code?.text ? (
          <div className="video-container">
            <div
              className="iframe"
              dangerouslySetInnerHTML={{ __html: embed_code.text }}
            />
            {
              embed_caption?.html ? (
                <div
                  className="video-caption caption"
                  dangerouslySetInnerHTML={{ __html: embed_caption.html }}
                />
              ) : null
            }
          </div>
        ) : null
      }
      {
        image?.gatsbyImageData ? (
          <div className="image-container">
            <Img image={image} />
          </div>
        ) : null
      }
    </div>
  );
}
