import React, { useEffect, useMemo, useState, useRef, useCallback } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useDebounce } from 'use-debounce';
import ArchiveListItem from './archive-list-item';
import { TextButton } from '../../../buttons';
import TextInput from '../../../text-input';
import { IconSearch, IconChevronDown } from '../../../icons';
import useMergePrismicPreviewData from '../../../../utils/use-merge-prismic-preview-data-fix';
import './styles.scss';

// const CHAR_TRANSFORMS = {
//   'Ā': 'A',
//   'Ṅ': 'N',
//   'Ṣ': 'S',
//   'Ṭ': 'T',
//   'Ḍ': 'D',
//   'Ṛ': 'R',
//   'Ī': 'I'
// };

const FILTERS = [
  {
    label: 'Articles',
    value: 'article'
  },
  {
    label: 'Announcements',
    value: 'announcement'
  },
  {
    label: 'Medical Considerations',
    value: 'medical consideration'
  },
  {
    label: 'Poses',
    value: 'pose'
  }
];

export function SliceArticleArchiveList () {
  const sliceRef = useRef(null);
  const [ currentPage, _setCurrentPage ] = useState(1);
  const [ activeFilter, _setActiveFilter ] = useState('article');
  const [ showButton, setShowButton ] = useState(true);
  const [ search, _setSearch ] = useState('');
  const [ searchDebounced ] = useDebounce(search, 200, { leading: true, maxWait: 600 });
  const [ mobileOpen, setMobileOpen ] = useState(false);
  const searchField = useRef(null);

  useEffect(() => {
    if (history?.state?.page) _setCurrentPage(history?.state?.page);
    if (history?.state?.activeFilter) _setActiveFilter(history?.state?.activeFilter);
    if (history?.state?.search) _setSearch(history?.state?.search);
  }, []);

  useEffect(() => {
    const handleClickBody = () => setMobileOpen(false);

    document.body.addEventListener('click', handleClickBody);

    return () => document.body.removeEventListener('click', handleClickBody);
  }, []);

  const setCurrentPage = useCallback((val) => {
    history.replaceState({ ...history.state, page: val }, '');
    _setCurrentPage(val);
  }, []);

  const setActiveFilter = useCallback((val) => {
    history.replaceState({ ...history.state, activeFilter: val }, '');
    _setActiveFilter(val);
  }, []);

  const setSearch = useCallback((val) => {
    history.replaceState({ ...history.state, search: val }, '');
    _setSearch(val);
  }, []);

  useEffect(() => {
    if (window.location.pathname === '/resources-pose-catalog') {
      setActiveFilter('pose');
    }
  }, [ setActiveFilter ]);

  useEffect(() => {
    if (!searchDebounced) return;

    window.dataLayer.push({ event: 'resources-search', search_term: searchDebounced });
  }, [ searchDebounced ]);

  const _data = useStaticQuery(graphql`
  query ArticleArchiveListQuery {
    allPrismicArticle(sort: {fields: data___publish_date, order: DESC}) {
      edges {
        node {
          _previewable
          url
          data {
            members_only
            article_thumbnail {
              gatsbyImageData
            }
            read_time
            publish_date(formatString: "MMMM D, YYYY")
            title {
              text
            }
            category
          }
        }
      }
    }

    allPrismicPose(sort: {fields: data___pose___name}) {
      edges {
        node {
          url
          _previewable
          data {
            pose {
              name
              nameSanskrit
              illustrations {
                tamika {
                  file {
                    url
                  }
                }
              }
              instructionalText
              alternativeNames
              category
            }
          }
        }
      }
    }
  }
  `);

  const data = useMergePrismicPreviewData(_data);

  const { edges } = data?.allPrismicArticle;
  const { edges: poseEdges } = data?.allPrismicPose;

  const getEdgesFiltered = useCallback(() => {
    let result;

    if (!activeFilter) {
      result = edges ?? [];
    } else if (activeFilter === 'pose') {
      result = poseEdges ?? [];
    } else {
      result = edges?.filter((a) => {
        if (!activeFilter) return true;

        return activeFilter === a.node?.data?.category?.toLowerCase();
      }) ?? [];
    }

    if (!searchDebounced) return result;

    const searchTermsR = `\\b(${searchDebounced.split(/\W/)
      .map(term => term.trim())
      .filter(term => term)
      .join('|')})`;

    return result.filter((a) => {
      const r = new RegExp(searchTermsR, 'img');

      a._score = 0;

      while (r.exec(a.node.data.title?.text)) a._score += 50;
      while (r.exec(a.node.data.pose?.name)) a._score += 50;
      while (r.exec(a.node.data.pose?.nameSanskrit)) a._score += 50;
      while (r.exec(a.node.data.pose?.alternativeNames)) a._score += 10;

      const categories = (a.node.data.pose?.category ?? []).join(' ');
      while (r.exec(categories)) a._score += 5;

      return a._score > 0;
    }).sort((a, b) => b._score - a._score);
  }, [ activeFilter, poseEdges, edges, searchDebounced ]);

  useEffect(() => {
    const edgesFiltered = getEdgesFiltered();

    const PER_PAGE = activeFilter === 'pose' ? 51 : 6;

    if (currentPage * PER_PAGE >= edgesFiltered.length) {
      setShowButton(false);
    } else {
      setShowButton(true);
    }
  }, [ currentPage, getEdgesFiltered, activeFilter ]);

  const handleLoadMoreClick = () => {
    setCurrentPage(currentPage + 1);
  };

  const renderedArticles = useMemo(() => {
    const edgesFiltered = getEdgesFiltered();

    if (!edgesFiltered.length) return null;

    if (activeFilter === 'pose') {
      return edgesFiltered.slice(0, currentPage * 51).map((edge, i) => {
        const { url, data: poseData } = edge.node;

        return (
          <ArchiveListItem
            key={`archive-list-item-pose-${i}`}
            data={poseData}
            url={url}
            type="pose"
          />
        );
      });
    }

    return edgesFiltered.slice(0, currentPage * 6).map((edge, i) => {
      const { data: poseData, url } = edge.node;
      return (
        <ArchiveListItem
          key={`archive-list-item-${i}`}
          data={poseData}
          url={url}
        />
      );
    });
  }, [ currentPage, activeFilter, getEdgesFiltered ]);

  const filters = useMemo(() => {
    return FILTERS.map((filter, i) => {
      const { label, value } = filter;

      const handleClick = () => {
        setActiveFilter(value);
        setCurrentPage(1);
      };

      return (
        <div
          className={`archive-filter ${value === activeFilter ? 'active' : ''}`}
          key={`archive-filter-${i}`}
          role="button"
          onClick={handleClick}
          onKeyDown={handleClick}
          aria-label="Article Filters"
        >
          { label }
        </div>
      );
    });
  }, [ activeFilter, setActiveFilter, setCurrentPage ]);

  const filtersRendered = useMemo(() => {
    const className = `filters-menu ${mobileOpen === 'filters' ? '--open' : ''}`;

    return ( <div className={className}>{filters}</div> );
  }, [ filters, mobileOpen ]);

  const handleSearchKeyUp = useCallback((ev) => {
    if (ev.key === 'Escape') setSearch('');

    if (ev.key === 'Enter' || ev.key === 'Escape') {
      ev.preventDefault();
      ev.stopPropagation();
      setMobileOpen(false);
      ev.target.blur();
    }
  }, [ setSearch ]);

  const handleClickMobileSearchButton = useCallback((ev) => {
    ev.stopPropagation();
    setMobileOpen('search');
    setTimeout(() => searchField.current.querySelector('input').focus(), 50);
  }, []);

  const searchRendered = useMemo(() => {
    return (
      <div className={`archive-search ${mobileOpen === 'search' ? '--open' : ''}`}>
        <TextInput
          ref={searchField}
          required={false}
          label="Search for..."
          value={search}
          handleChange={ev => setSearch(ev.target.value)}
          endAdornment={<IconSearch />}
          onKeyUp={handleSearchKeyUp}
          onClick={ev => ev.stopPropagation()}
          id="siteSearch"
        />
        <div
          className="mobile-search-button"
          onClick={handleClickMobileSearchButton}
        >
          <IconSearch />
        </div>
      </div>
    );
  }, [ mobileOpen, search, setSearch, handleSearchKeyUp, handleClickMobileSearchButton ]);

  const mobileFiltersButton = useMemo(() => {
    let searchIdentification = null;

    if (searchDebounced) {
      searchIdentification = (
        <span className="searching-for">Search results: {searchDebounced}</span>
      );
    }

    const handleClick = (ev) => {
      ev.preventDefault();
      ev.stopPropagation();
      setMobileOpen('filters');
    };

    return (
      <div className="mobile-filters-button">
        <div
          className={`archive-filter active`}
          role="button"
          onClick={handleClick}
          onKeyDown={handleClick}
          aria-label="Article Filters"
        >
          {FILTERS.find(f => f.value === activeFilter)?.label}
          <IconChevronDown />
          {searchIdentification}
        </div>
      </div>
    );
  }, [ activeFilter, searchDebounced ]);

  return (
    <section className="slice-module article-archive-list" ref={sliceRef}>
      <div className="article-archive-list__inner container">
        <div className="filters">
          {mobileFiltersButton}
          {filtersRendered}
          {searchRendered}
        </div>
        <div className="articles-list">
          { renderedArticles }
        </div>
        {
          showButton ? (
            <div className="load-more-button">
              <TextButton onClick={handleLoadMoreClick}>Load More Resources</TextButton>
            </div>
          ) : null
        }
      </div>
    </section>
  );
}

export const query = graphql`
fragment ArticleArchiveListFields on PrismicPageDataBodyArticleArchiveList {
  id
  slice_type
}
`;
