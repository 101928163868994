import React from 'react';
import { graphql } from 'gatsby';

export function SliceCustomCode ({ primary }) {
  return (
    <section
      className="slice-module custom-code"
      dangerouslySetInnerHTML={{ __html: primary.code.text }}
    />
  );
}

export const query = graphql`
fragment CustomCodeFields on PrismicPageDataBodyCustomCode {
  id
  slice_type

  primary {
    code { text }
  }
}

fragment PoseBodyCustomCodeFields on PrismicPoseDataBodyCustomCode {
  id
  slice_type

  primary {
    code { text }
  }
}

fragment PoseBody1CustomCodeFields on PrismicPoseDataBody1CustomCode {
  id
  slice_type

  primary {
    code { text }
  }
}

fragment ArticleBodyCustomCodeFields on PrismicArticleDataBodyCustomCode {
  id
  slice_type

  primary {
    code { text }
  }
}

fragment ArticleBody1CustomCodeFields on PrismicArticleDataBody1CustomCode {
  id
  slice_type

  primary {
    code { text }
  }
}
`;
