import React, { useContext } from 'react';
import { BackgroundImage } from '../../../image';
import { LinkWrapper } from '../../../link-wrapper';
import { IconSubscriberGate } from '../../../icons';
import { AuthContext } from '../../../auth';

export function ArticleListing ({ url, articleData }) {
  const auth = useContext(AuthContext);

  const {
    read_time,
    publish_date,
    title,
    article_thumbnail,
    members_only
  } = articleData;

  return (
    <LinkWrapper url={url} className="article-listing">
      <div className="img-container">
        <BackgroundImage image={article_thumbnail} />
      </div>
      <div className="content-container">
        <span className="metadata publish-date">{publish_date}</span>
        <h4 className="h4 featured-article-title">{title.text}</h4>
        <span className="readtime">{read_time}</span>
        {
          members_only && !auth.subscription?.active ? (
            <span className="members-only"><IconSubscriberGate /> Subscribers Only</span>
          ) : null
        }
      </div>
    </LinkWrapper>
  );
}
