/* global process */
import React, { useMemo } from 'react';
import SliceZone from '../..';
import { TextButton } from '../../../buttons';

function fixProdEnv (url) {
  if (!process.env.GATSBY_PROD_ENV) return url;
  return url.replace('.yogatherapylibrary.com',
    `.${process.env.GATSBY_PROD_ENV}.yogatherapylibrary.com`);
}

export default function SubscriptionColumn ({
  data, pricingModel, activeOnMobile, popular
}) {
  const columnHeading = useMemo(() => {
    const {
      name: subName,
      price_per_month,
      price_per_year,
      effective_monthly_price_billed_yearly,
      yearly_savings_line
    } = data;

    const yearlyPrice = pricingModel === 'yearly' && (
      <h6 className="yearly-price">
        <strong>${price_per_year}</strong> billed annually
      </h6>
    );

    return (
      <div className="sub-column-heading">
        <h3 className="sub-name">{subName}</h3>
        <div className="price-container">
          <span className="dollar-sign">$</span>
          <span className="price">{
            pricingModel === 'monthly' ? price_per_month : effective_monthly_price_billed_yearly
          }</span>
          <span className="frequency">/ month</span>
        </div>
        <div className="yearly-info">
          {yearlyPrice}
          <div className="yearly-savings" dangerouslySetInnerHTML={{ __html: yearly_savings_line.html }} />
        </div>
      </div>
    );
  }, [ data, pricingModel ]);

  const columnBody = useMemo(() => {
    return (
      <SliceZone sliceZone={data.body} />
    );
  }, [ data.body ]);

  const ctaButton = useMemo(() => {
    const ctaLink = pricingModel === 'yearly' ?
      data.yearly_cta_link :
      data.monthly_cta_link;

    return (
      <TextButton
        to={fixProdEnv(ctaLink.url)}
        target={ctaLink.target}
      >
        {data.cta_text}
      </TextButton>
    );
  }, [ data.yearly_cta_link, data.monthly_cta_link, data.cta_text, pricingModel ]);

  const classes = [
    'sub-column',
    activeOnMobile ? 'active' : '',
    popular ? 'popular' : ''
  ].join(' ');

  return (
    <div className={classes}>
      { popular && (
        <div className="popular-flag">
          <span>Best Value</span>
        </div>
      )}
      <div className="sub-column-inner">
        {columnHeading}
        <div className="column-body">
          {columnBody}
        </div>
        <div className="button-container">
          {ctaButton}
        </div>
      </div>
    </div>
  );
}
