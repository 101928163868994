import { graphql } from 'gatsby';
import React, { useMemo } from 'react';
import './styles.scss';

export function SubscriptionFeaturesList ({ items }) {
  const featureList = useMemo(() => {
    if (!items?.length) return null;

    return items.map((item, i) => {
      return (
        <p
          key={`tier-feature-${i}`}
          className="single-feature"
        >
          {item.feature}
        </p>
      );
    });
  }, [ items ]);

  return (
    <div className="sub-module features-list">
      {featureList}
    </div>
  );
}

export const query = graphql`
fragment SubscriptionFeaturesListFields on PrismicSubscriptionDataBodyFeaturesList {
  id
  items {
    feature
  }
  slice_type
}
`;
