/* globals process */
import React from 'react';
import { Img } from '../../../image';
import { IconSubscriberGate } from '../../../icons';
import { TextButton } from '../../../buttons';

function fixProdEnv (url) {
  if (!process.env.GATSBY_PROD_ENV) return url;
  return url.replace('.yogatherapylibrary.com',
    `.${process.env.GATSBY_PROD_ENV}.yogatherapylibrary.com`);
}

export default function ArticleContentItem ({ data, blocked }) {
  const {
    content,
    divider_above,
    divider_below,
    image,
    image_configuration
  } = data;

  const classes = [
    'article-content-item',
    `${image_configuration?.toLowerCase() ?? 'full-width'}-image`,
    divider_above ? '--divider-above' : '',
    divider_below ? '--divider-below' : '',
    blocked ? '--blocked' : ''
  ];

  const imageRendered = !image?.gatsbyImageData ? null : (
    <div className="image-container">
      <Img image={image} />
      { !image.alt ? null : (
        <p className="caption">{image.alt}</p>
      ) }
    </div>
  );

  return (
    <div className={classes.join(" ")}>
      {imageRendered}
      <div
        className="content-container"
        dangerouslySetInnerHTML={{ __html: content.html }}
      />
      { blocked ? (
        <div className="article-blocker">
          <div className="article-blocker__inner">
            <div className="sub-gate">
              <IconSubscriberGate />
              <span className="metadata sub-gate-text">Subscribers Only</span>
            </div>

            <h5 className="h5">Subscribe to Yoga Therapy Library to access this resource—and so many more!</h5>
            <TextButton to={fixProdEnv(process.env.LOG_IN_URL)}>Subscribe, today</TextButton>
          </div>
        </div>
      ) : null}
    </div>
  );
}
