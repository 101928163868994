import React, { useMemo, useContext } from 'react';
import { ArticleListing } from './article-listing';
import NewsletterSignup from '../../../newsletter-signup';
import { Img } from '../../../image';
import { graphql } from 'gatsby';
import './styles.scss';
import { TextButton } from '../../../buttons';
import { AuthContext, hideForVisibility } from '../../../auth';

export function SliceFeaturedArticlesList ({ id, items, primary }) {
  const auth = useContext(AuthContext);

  const {
    cta_image,
    cta_title,
    cta_button_link,
    cta_button_text,
    newsletter_signup_title,
    newsletter_signup_button_text,
    content
  } = primary;

  const articlesRendered = useMemo(() => {
    if (!items || !items.length) return null;

    return items.map((item, i) => {
      if (!item.featured_article?.document) return null;

      const { data, url } = item.featured_article.document;

      return (
        <ArticleListing
          key={`article-listing-${i}`}
          url={url}
          articleData={data}
        />
      );
    });
  }, [ items ]);

  if (hideForVisibility(auth, primary)) return null;

  return (
    <section className="slice-module featured-articles-list">
      <div
        className="featured-articles-list-content container"
        dangerouslySetInnerHTML={{ __html: content.html }}
      />
      <div className="featured-articles-list__inner container">
        <div className="article-list">
          { articlesRendered }
        </div>
        <div className="cta-sidebar">
          <div className="cta-card">
            <Img image={cta_image} />
            <h5 className="h5 cta-title">{cta_title.text}</h5>
            <TextButton to={cta_button_link.url}>
              {cta_button_text.text}
            </TextButton>
          </div>
          <NewsletterSignup
            heading={newsletter_signup_title}
            buttonText={newsletter_signup_button_text}
            id={`newsletter-signup-${id}`}
          />
        </div>
      </div>
    </section>
  );
}

export const query = graphql`
fragment FeaturedArticlesListFields on PrismicPageDataBodyFeaturedArticlesList {
  id
  slice_type

  primary {
    visibility
    content { html }
    cta_image {
      gatsbyImageData
    }
    cta_title {
      text
    }
    cta_button_link {
      url
    }
    cta_button_text {
      text
    }
    newsletter_signup_title {
      text
    }
    newsletter_signup_button_text {
      text
    }
  }
  items {
    featured_article {
      document {
        ... on PrismicArticle {
          _previewable
          id
          url
          data {
            members_only
            article_thumbnail {
              gatsbyImageData
            }
            read_time
            publish_date(formatString: "MMMM DD, YYYY")
            title {
              text
            }
          }
        }
      }
    }
  }
}
fragment ArticleFeaturedArticlesListFields on PrismicArticleDataBody1FeaturedArticlesList {
  id
  slice_type

  primary {
    cta_image {
      gatsbyImageData
    }
    cta_title {
      text
    }
    cta_button_link {
      url
    }
    cta_button_text {
      text
    }
    newsletter_signup_title {
      text
    }
    newsletter_signup_button_text {
      text
    }
  }
  items {
    featured_article {
      document {
        ... on PrismicArticle {
          _previewable
          id
          url
          data {
            members_only
            article_thumbnail {
              gatsbyImageData
            }
            read_time
            publish_date(formatString: "MMMM DD, YYYY")
            title {
              text
            }
          }
        }
      }
    }
  }
}
fragment PoseFeaturedArticlesListFields on PrismicPoseDataBody1FeaturedArticlesList {
  id
  slice_type

  primary {
    cta_image {
      gatsbyImageData
    }
    cta_title {
      text
    }
    cta_button_link {
      url
    }
    cta_button_text {
      text
    }
    newsletter_signup_title {
      text
    }
    newsletter_signup_button_text {
      text
    }
  }
  items {
    featured_article {
      document {
        ... on PrismicArticle {
          _previewable
          id
          url
          data {
            members_only
            article_thumbnail {
              gatsbyImageData
            }
            read_time
            publish_date(formatString: "MMMM DD, YYYY")
            title {
              text
            }
          }
        }
      }
    }
  }
}
`;
