import React from 'react';
import { graphql } from 'gatsby';
import ArchiveListItem from '../article-archive-list/archive-list-item';
import './styles.scss';

export function SliceArticlesList ({ primary, items }) {
  const { heading } = primary;

  const articlesRendered = items.map(({ articles }, index) => {
    return (
      <ArchiveListItem
        key={index}
        url={articles.url}
        data={articles.document.data}
      />
    );
  });

  return (
    <section className="slice-module module-articles-list">
      <h3 className="h3 section-title">{heading.text}</h3>
      <div className="__articles">
        {articlesRendered}
      </div>
    </section>
  );
}

export const query = graphql`
fragment ArticlesListFields on PrismicArticleDataBodyArticlesList {
  id, slice_type

  primary {
    heading { text }
  }

  items {
    articles {
      url
      document {
        ... on PrismicArticle {
          _previewable
          data {
            members_only
            article_thumbnail { gatsbyImageData }
            title { text }
            publish_date(formatString: "MMMM D, YYYY")
            read_time
          }
        }
      }
    }
  }
}
`;
