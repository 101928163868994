import React, { useContext } from 'react';
import { graphql } from 'gatsby';
import './styles.scss';
import { TextButton } from '../../../buttons';
import { AuthContext, hideForVisibility } from '../../../auth';

export const SliceCenteredCTA = ({ primary }) => {
  const auth = useContext(AuthContext);

  if (hideForVisibility(auth, primary)) return null;

  const {
    section_title,
    section_body,
    cta_text,
    cta_url,
    divider_line_color
  } = primary;

  return (
    <section className="slice-module centered-cta">
      <div className="centered-cta__inner container">
        <div
          className="lede-container"
          dangerouslySetInnerHTML={{ __html: section_title.html }}
        />
        <div className={`divider centered divider-${divider_line_color.toLowerCase()}`} />
        <div className="body-container">
          <div
            className="body-text"
            dangerouslySetInnerHTML={{ __html: section_body.html }}
          />
          {
            cta_url?.url ? (
              <TextButton to={cta_url.url}>
                { cta_text.text }
              </TextButton>
            ) : null
          }
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
fragment CenteredCTAFields on PrismicPageDataBodyCenteredCtaSection {
  id
  slice_type

  primary {
    visibility
    section_title {
      html
    }
    section_body {
      html
    }
    cta_text {
      text
    }
    cta_url {
      url
    }
    divider_line_color
  }
}
`;
