import React, { useContext } from 'react';
import { BackgroundImage } from '../../../image';
import { IconSubscriberGate } from '../../../icons';
import { Link } from 'gatsby';
import { AuthContext } from '../../../auth';

export default function ArchiveListItem ({ data, url, type }) {
  const auth = useContext(AuthContext);

  if (type === 'pose') {
    const {
      name: poseName,
      nameSanskrit,
      illustrations
    } = data.pose;

    const subGate = auth.subscription?.active ? null : (
      <div className="sub-gate">
        <IconSubscriberGate />
        <span className="metadata">Subscribers Only</span>
      </div>
    );

    const poseDataRendered = (
      <>
        <div className="image-container">
          <div className="pose-img-wrapper">
            <img src={illustrations.tamika.file.url} />
          </div>
        </div>
        <div className="content-container">
          {
            nameSanskrit ? (
              <div className="metadata-container">
                <span className="metadata">{nameSanskrit}</span>
              </div>
            ) : null
          }
          <h6 className="h5 article-feature-title">{poseName}</h6>
        </div>
        {subGate}
      </>
    );

    if (auth.subscription?.active) {
      return (
        <Link to={url} className="archive-list-item --type-pose">
          {poseDataRendered}
        </Link>
      );
    }

    return (
      <div className="archive-list-item --type-pose">
        {poseDataRendered}
      </div>
    );
  }

  const {
    members_only,
    article_thumbnail,
    read_time,
    publish_date,
    title
  } = data;

  return (
    <Link to={url} className="archive-list-item">
      <div className="image-container">
        <BackgroundImage image={article_thumbnail} />
      </div>
      <div className="content-container">
        <div className="metadata-container">
          <span className="metadata">{publish_date}</span>
          <span className="readtime --above-mobile">{read_time}</span>
        </div>
        <h5 className="h5 article-feature-title">{title.text}</h5>
        <span className="readtime --mobile-only">{read_time}</span>
        { members_only && !auth.subscription?.active ? (
          <div className="sub-gate">
            <IconSubscriberGate />
            <span className="metadata">Subscribers Only</span>
          </div>
        ) : null}
      </div>
    </Link>
  );
}
