/*globals process*/
import React, { useMemo, useContext } from 'react';
import { TextButton } from '../../../buttons';
import { CheckMarkIcon } from './icons';
import { graphql, useStaticQuery } from 'gatsby';
import useMergePrismicPreviewData from '../../../../utils/use-merge-prismic-preview-data-fix';
import { AuthContext, hideForVisibility } from '../../../auth';

import './styles.scss';

function fixProdEnv (url) {
  if (!process.env.GATSBY_PROD_ENV) return url;
  return url.replace('.yogatherapylibrary.com',
    `.${process.env.GATSBY_PROD_ENV}.yogatherapylibrary.com`);
}

export const SliceSubscriptionPricing = ({ primary, items }) => {
  const auth = useContext(AuthContext);

  const _data = useStaticQuery(graphql`
    query SubscriptionPlansQuery {
      prismicGlobalOptions {
        _previewable
        data {
          subscription_plans {
            name
            link { url }
          }
        }
      }
    }
  `);

  const data = useMergePrismicPreviewData(_data);

  const {
    subscription_plans
  } = data.prismicGlobalOptions?.data;

  const {
    section_intro_text,
    section_title,
    legal_text
  } = primary;

  const tiersRendered = useMemo(() => {
    if (!items || !items.length) return null;

    const subscriptionPlans = new Map(subscription_plans.map((sp) => {
      return [ sp.name, sp ];
    }));

    return items.map((item, i) => {
      const {
        button_text,
        normal_price,
        perks,
        price,
        price_term,
        recommended
      } = item;

      const sp = subscriptionPlans.get(price_term.text);

      if (!sp) {
        console.warn(`Subscription plan ${price_term.text} is missing`);
        return null;
      }

      const priceFloat = parseFloat(price.text);

      const handleGaEvent = () => {
        window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
        window.dataLayer.push({
          event: 'add_to_cart',
          ecommerce: {
            currency: 'USD',
            value: priceFloat,
            items: [
              {
                item_id: price_term.text,
                item_name: price_term.text,
                price: priceFloat
              }
            ]
          }
        });
      };

      return (
        <div
          key={`sub-tier-${i}`}
          className={`sub-tier ${recommended ? 'recommended' : ''}`}
        >
          {
            recommended ? (
              <span className="reco-text">Recommended</span>
            ) : null
          }
          <div className="price-container">
            <span className="term">{price_term.text}</span>
            <div className="price">
              <span className="currency">$</span>
              <h4 className="price-text">{price.text}</h4>
            </div>
            <span className="normal-price">
              {
                normal_price?.text ? (
                  `Normally $${normal_price.text}`
                ) : null
              }
            </span>
          </div>
          <div className="divider divider-peach centered" />
          <div
            className="perks-list-wrapper"
            dangerouslySetInnerHTML={{ __html: perks.html }}
          />
          <TextButton to={fixProdEnv(sp.link.url)} onClick={handleGaEvent}>
            { button_text.text }
          </TextButton>
        </div>
      );
    });
  }, [ items, subscription_plans ]);

  if (primary.visibility && hideForVisibility(auth, primary)) return null;

  return (
    <section className="slice-module subscription-pricing">
      <div className="subscription-pricing__bg" />
      <div className="subscription-pricing__inner container">
        <div className="intro-container">
          <h3 className="h3 section-title">{section_title.text}</h3>
          <div
            className="intro-text"
            dangerouslySetInnerHTML={{ __html: section_intro_text.html }}
          />
        </div>
        <div className="tiers-container">
          {tiersRendered}
        </div>
        <div
          className="legal-text"
          dangerouslySetInnerHTML={{ __html: legal_text.html }}
        />
      </div>
    </section>
  );
};

export const query = graphql`
fragment SubscriptionPricingFields on PrismicPageDataBodySubscriptionPricing {
  id
  slice_type

  primary {
    visibility
    section_intro_text {
      html
    }
    section_title {
      text
    }
    legal_text {
      html
    }
  }

  items {
    button_text {
      text
    }
    normal_price {
      text
    }
    perks { html }
    price {
      text
    }
    price_term {
      text
    }
    recommended
  }
}
fragment ArticleSubscriptionPricingFields on PrismicArticleDataBody1SubscriptionPricing {
  id
  slice_type

  primary {
    section_intro_text {
      html
    }
    section_title {
      text
    }
    legal_text {
      html
    }
  }

  items {
    button_text {
      text
    }
    normal_price {
      text
    }
    perks { html }
    price {
      text
    }
    price_term {
      text
    }
    recommended
  }
}

fragment PoseSubscriptionPricingFields on PrismicPoseDataBody1SubscriptionPricing {
  id
  slice_type

  primary {
    section_intro_text {
      html
    }
    section_title {
      text
    }
    legal_text {
      html
    }
  }

  items {
    button_text {
      text
    }
    normal_price {
      text
    }
    perks { html }
    price {
      text
    }
    price_term {
      text
    }
    recommended
  }
}
`;
