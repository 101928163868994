import React from 'react';
import { SliceHeroCarousel } from './slices/hero-carousel';
import { SliceCenteredCTA } from './slices/centered-cta';
import { SliceThreeColCTA } from './slices/three-col-cta';
import { SliceAdvisorsGrid } from './slices/advisors-grid';
import { SliceSubscriptionPricing } from './slices/subscription-pricing';
import { SliceSubscriptionPricing2 } from './slices/subscription-pricing2';
import { SliceFeaturedTestimonial } from './slices/featured-testimonial';
import { SliceComingSoonArticle } from './slices/coming-soon-article';
import { SliceVideoEmbed } from './slices/video-embed';
import { SliceTwoColumnSection } from './slices/two-column-section';
import { SliceFeaturedArticlesList } from './slices/featured-articles-list';
import { SliceArticleContent } from './slices/article-content';
import { SliceArticleDownloads } from './slices/article-downloads';
import { SliceArticleMedia } from './slices/article-media';
import { SliceArticleArchiveList } from './slices/article-archive-list';
import { SliceInteractivePoseFeature } from './slices/interactive-pose-feature';
import { SliceArticlesList } from './slices/articles-list';
import { SliceCustomCode } from './slices/custom-code';
import { SubscriptionImageText } from './slices/subscription-image-and-text';
import { SubscriptionFeaturesList } from './slices/subscription-features-list';

const SLICE_COMPONENTS = {
  hero_carousel: SliceHeroCarousel,
  centered_cta_section: SliceCenteredCTA,
  three_col_cta: SliceThreeColCTA,
  advisors_grid: SliceAdvisorsGrid,
  subscription_pricing: SliceSubscriptionPricing,
  subscription_pricing_2: SliceSubscriptionPricing2,
  image_and_text: SubscriptionImageText,
  features_list: SubscriptionFeaturesList,
  featured_testimonial: SliceFeaturedTestimonial,
  coming_soon_article_feature: SliceComingSoonArticle,
  video_embed: SliceVideoEmbed,
  two_column_section: SliceTwoColumnSection,
  featured_articles_list: SliceFeaturedArticlesList,
  article_content: SliceArticleContent,
  article_downloads: SliceArticleDownloads,
  article_video: SliceArticleMedia,
  article_archive_list: SliceArticleArchiveList,
  articles_list: SliceArticlesList,
  interactive_pose_feature: SliceInteractivePoseFeature,
  custom_code: SliceCustomCode
};

export default function SliceZone ({ sliceZone }) {
  const sliceZoneContent = sliceZone?.map((slice, index) => {
    if (!slice.slice_type) return null;

    const SliceComponent = SLICE_COMPONENTS[slice.slice_type];

    if (!SliceComponent) {
      throw new Error(`Invalid slice type: ${JSON.stringify(slice)}`);
    }

    return ( <SliceComponent {...slice} key={index} /> );
  });

  return <>{sliceZoneContent}</>;
}
